.leftControl,
.rightControl {
  @apply absolute z-20;
  top: 40%;
  outline: none;
  height: 48px;
  width: 48px;
  opacity: 0.5;
  background-color: black;
  transition: background-color 0.2s ease;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.controlFull {
  width: 78px;
  height: 78px;
}

.controlFull svg {
  width: 24px;
  height: 24px;
}

.leftControl {
  left: 8px;
}

.rightControl {
  right: 8px;
}

.leftControl:hover,
.rightControl:hover,
.leftControl:focus,
.rightControl:focus {
  @apply outline-none;
}

.leftControl {
  @apply bg-cover left-10;
}

.rightControl {
  @apply bg-cover right-10;
}
